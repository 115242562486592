import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import ReactMarkdown from 'react-markdown';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import Video from 'components/Video';
import {ImgCard} from 'components/Elements/ImgBullet';
import {ListIcon} from 'components/Elements/IconList';
import OptinModal, {OptinModalTrigger} from 'components/OptinModal';
import Modal from 'components/Modal';
import ModalExitLanding from 'components/Modals/ModalExitLanding';
import Footer from 'components/Elements/Footer';

//@ts-ignore
import {workshopSpeakerList} from 'data/pbcc-workshop.json';
//@ts-ignore
import {speakers} from 'data/module.json';

export default function WorkshopIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = null,
  ctaNoTimer = false,
  variant = 'default',
  ...props
}) {
  const pageTitle = 'Join the FREE Plant-Based Coaching Workshop';
  const pageDescription = 'Transform your passion for plant-based foods into a thriving career.';
  const ogImage =
    'https://s3.us-west-2.amazonaws.com/cdn.foodrevolution.org/pbcc/og/pbcc-w-20250820-open-graph-facebook.jpg';
  const shareUrl = 'https://certification.foodrevolution.org/workshop/';
  const facebookDescription =
    'Passionate about the plant-based movement? Turn that passion into a career with our specialized coaching program. Step up, make an impact, and be a beacon of health and sustainability. Register for free here.';
  const twitterDescription =
    'Drive change in the world of health and nutrition! Dive into our #PlantBasedCoaching Program and lead the Food Revolution. Register for free here.';
  const twitterImg =
    'https://s3.us-west-2.amazonaws.com/cdn.foodrevolution.org/pbcc/og/pbcc-w-20250820-open-graph-twitter.jpg';
  const pinterestDescription =
    'Seeking a career aligned with your love for plant-based foods? Our coaching certification is your gateway to making a difference, promoting health, and championing sustainability. Pin to inspire others to join the movement! Register for free here.';
  const pinterestImg =
    'https://s3.us-west-2.amazonaws.com/cdn.foodrevolution.org/pbcc/og/pbcc-w-20250820-open-graph-pinterest.jpg';

  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const phase = defaultSchedule.phase;
  const ctaButtonCopy = 'Save My Spot';

  const [sectionImageControl, setsectionImageControl] = useState(props.sectionImageControl || 'control');
  useEffect(() => {
    subscribeToCampaign((variantIndex) => {
      switch (variantIndex) {
        case 1:
          setsectionImageControl('v1');
          break;
      }
    }, 1078);
  }, []);

  const headerCtaHeadlines = (
    <div className="text-center">
      <p>Free Live Special Event</p>
      <h2 className="mt-0">
        Food Revolution Network’s Plant-Based Coaching Workshop: <i>The Recipe for Real and Lasting Change</i>
      </h2>
      <h4 className="text-400 mt-0">Bring the Food Revolution to your clients, your loved ones, and yourself!</h4>
      <h5 className="mt-0 mb-3">August 23–25, 2024</h5>
      <p className="balance-text mb-5">
        <b>How It Works:</b> Each day of the workshop, we will broadcast a new session starting at 12 pm ET / 9 am PT /
        4 pm GMT. After each day’s screening ends, the workshop will be available on replay for a day. Each session will
        run for about 3 hours.
      </p>
    </div>
  );
  const headerOptin = (
    <div className="text-center">
      <OptinModalTrigger>{ctaButtonCopy}</OptinModalTrigger>
    </div>
  );
  const landingVideo = (
    <Video
      url="https://www.youtube.com/watch?v=iOH6KwJAGGo"
      label="PBCC - Workshop - Trailer"
      thumbnail="https://cdn.foodrevolution.org/pbcc/video-thumb/pbcc-dynamic-thumbnail-20230919.gif"
      noButton
    />
  );

  useBalanceText();
  return (
    <div className="page page-nosocial d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div className="page-content">
        <Header
          bgColor="purple"
          lockupWhite
          logoUrlOverride="https://cdn.foodrevolution.org/pbcc/pbcc-logo-workshop-white.svg"
          logoAltOverride="plant-based coaching workshop logo"
          logoWidthOverride={300}
          logoHeightOverride={59}
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />

        <Section id="pbccw-header-cta" color="black" angle="none">
          <Container>
            <Row>
              <Col>
                <div className="background-light-gray rounded box-shadow-black-50 p-4 p-lg-5">
                  {headerCtaHeadlines}
                  {headerOptin}
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="pbccw-video" color="light-gray" angle="both">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                {landingVideo}
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="pbccw-movement"
          color="white"
          imgPosition="right"
          imgUrl="https://cdn.foodrevolution.org/pbcc/images/1494492867-square.jpg"
        >
          <Container>
            <Row>
              <Col>
                <p>
                  {
                    {
                      default: (
                        <>
                          The plant-based movement is growing by leaps and bounds. Around the world, doctors, hospitals,
                          companies, health insurers, and governments are promoting plant-based lifestyles to support
                          human health and environmental sustainability. More than half of all Americans now want to eat
                          more plant foods, and tens of millions of people are asking, “Is a plant-based diet right for
                          me?”
                        </>
                      ),
                      ad: (
                        <>
                          The plant-based movement is growing by leaps and bounds. Around the world, doctors, hospitals,
                          companies, and institutions are promoting plant-based lifestyles to support human health and
                          environmental sustainability. More than half of all Americans now want to eat more plant
                          foods, and tens of millions of people are asking, “Is a plant-based diet right for me?”
                        </>
                      )
                    }[variant]
                  }
                </p>
                <p>
                  But building a more plant-based world takes <i>more</i> than just telling people to eat more
                  vegetables and fewer animal products.
                </p>
                <p>Effective plant-based coaching takes three things:</p>
                <p>
                  <b>Nutritional education.</b>
                </p>
                <p>
                  <b>Powerful coaching skills.</b>
                </p>
                <p>
                  <b>And the training to make an Impact.</b>
                </p>
                <p>And that’s exactly what we’re covering in this upcoming three-part workshop series.</p>
              </Col>
            </Row>
          </Container>
        </SectionImage>

        <SectionImage
          id="pbccw-series"
          color="white"
          imgPosition="left"
          imgUrl={
            {
              control: 'https://cdn.foodrevolution.org/pbcc/images/1477929691-square.jpg',
              v1: 'https://cdn.foodrevolution.org/pbcc/images/1423997728-square.jpg'
            }[sectionImageControl]
          }
        >
          <Container>
            <Row>
              <Col>
                <p>
                  {
                    {
                      default: (
                        <>
                          Coaches who master the art of guiding clients through their transformative journeys are poised
                          to thrive in the coming years and make a positive impact on entire communities. They will
                          engage in meaningful work, start or grow their own businesses, secure jobs with innovative
                          companies and organizations, and become trailblazers, leading the food revolution on a global
                          scale. The same principles that empower you to change the world can also be applied to
                          coaching your loved ones — or even yourself.
                        </>
                      ),
                      ad: (
                        <>
                          Coaches who master the art of guiding clients through their transformative journeys are poised
                          to thrive in the coming years and make a positive impact on entire communities. They will
                          engage in meaningful work, start or grow their own businesses, secure jobs with innovative
                          companies and organizations, and become trailblazers, leading the food revolution on a global
                          scale. The same principles that empower you to change the world can also be applied to
                          coaching your loved ones — or even yourself.
                        </>
                      )
                    }[variant]
                  }
                </p>
                {variant === 'default' && (
                  <p>
                    <b>And it can’t wait.</b>
                  </p>
                )}
                <p>The modern diet is fueling an epidemic of disease.</p>
                <p>
                  Industrialized agriculture has become one of the most environmentally destructive forces on the
                  planet.
                </p>
                <p>
                  {
                    {
                      default: <>And, we can do better. We can save lives. And the time for urgent action is now.</>,
                      ad: <>The time for urgent action is now.</>
                    }[variant]
                  }
                </p>
                <p>
                  <b>
                    Never before has there been such a tremendous opportunity to be a beacon of hope and an agent of
                    change.
                  </b>
                </p>
              </Col>
            </Row>
          </Container>
        </SectionImage>

        <Section id="pbccw-discover" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center">
                  {
                    {
                      default: <>In this content-rich LIVE workshop, you’ll discover:</>,
                      ad: <>In this content-rich LIVE workshop, we’ll share:</>
                    }[variant]
                  }
                </h3>
                <div className="d-flex justify-content-center px-4">
                  <ListIcon>
                    <>
                      {
                        {
                          default: (
                            <>
                              What the latest research tells us about the exact foods that have the power to prevent —
                              and in many cases, reverse — disease. You’ll learn the simple dietary shifts that can kick
                              off a healing transformation and the specific nutrients that are shown to increase
                              lifespan, improve heart health, starve cancer, slash the risk of dementia, decrease type 2
                              diabetes markers, and much more.
                            </>
                          ),
                          ad: (
                            <>
                              What the latest research says about the exact foods that have the power to impact disease
                              markers. Learn the simple dietary shifts that can kick off a healing transformation and
                              the specific nutrients that are linked to a long lifespan.
                            </>
                          )
                        }[variant]
                      }
                    </>
                    <>
                      {
                        {
                          default: (
                            <>
                              The common pitfalls of a plant-based diet… and how to overcome the dangers so you can
                              guide clients to gain maximum benefits without risking their health.
                            </>
                          ),
                          ad: (
                            <>
                              The common pitfalls of a plant-based diet… and how to overcome the dangers so clients can
                              gain maximum benefits without risking health.
                            </>
                          )
                        }[variant]
                      }
                    </>
                    <>
                      {
                        {
                          default: (
                            <>
                              The big mistake that outdated coaching techniques make — and how to motivate your clients
                              to go from <i>wanting</i> to change… to <i>doing</i> it.
                            </>
                          ),
                          ad: (
                            <>
                              The big mistake that outdated coaching techniques make — and how to motivate clients to go
                              from <i>wanting</i> to change… to <i>doing</i> it.
                            </>
                          )
                        }[variant]
                      }
                    </>
                    {variant === 'default' && (
                      <>
                        FRN’s essential coaching skills and unique techniques — plus, you’ll witness the magic of
                        coaching in action with an engaging live demonstration.
                      </>
                    )}
                    <>
                      How to unleash your potential by exploring dynamic coaching pathways — whether you start your own
                      thriving business, find fulfilling employment, support friends and loved ones, or all three!
                    </>
                    <>
                      Inspiring examples of how to be of service in at-risk communities and straightforward but highly
                      effective ways to bring the plant-based movement to the world.
                    </>
                  </ListIcon>
                </div>
                <p className="text-center mt-5 mb-0">
                  <OptinModalTrigger>Register for the Free Workshop</OptinModalTrigger>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="pbccw-bonus" color="white" className="text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">Join Us in this Fun Workshop and Receive This Guidebook (for free):</h2>
                <h4 className="mt-0">
                  <i>27 Rewarding Paths to a Thriving Career as a Plant-Based Coach</i>
                </h4>
                <img
                  className="mb-4 w-auto"
                  src="https://cdn.foodrevolution.org/pbcc/images/pbcc-27-rewarding-paths-3d-20230925.png"
                  alt="27 rewarding paths to a thriving career as a plant-based coach cover image"
                  width={2200}
                  height={2200}
                  style={{maxHeight: '500px'}}
                />
                <p>
                  This comprehensive guide illuminates a multitude of exciting pathways to a career in plant-based
                  nutrition and coaching. Whether you dream of starting your own coaching business, finding a job, or
                  forging meaningful change in your community, this guide will inspire and empower you to find exciting
                  new opportunities in the world of plant-based wellness.
                </p>
                <p>Save your spot, show up live, and get this bonus for FREE.</p>
                <p className="mt-5 mb-0">
                  <OptinModalTrigger>RSVP and Get the Free Guidebook</OptinModalTrigger>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="pbccw-ocean" color="black" angle="none">
          <Container>
            <Row className="justify-content-center">
              <Col lg="11">
                <div className="background-white rounded box-shadow-black-50 p-4 p-lg-5">
                  <Row className="align-items-center mb-4">
                    <Col xs="12" md="4" lg="2" className="mb-4 mb-md-0">
                      <img
                        className="rounded-circle"
                        src="https://cdn.foodrevolution.org/global/team/ocean-robbins-headshot.jpg"
                      />
                    </Col>
                    <Col xs="12" md="8" lg="10">
                      <h3 className="section-only-heading">Hi, I’m Ocean Robbins</h3>
                    </Col>
                  </Row>
                  <p>Interest in plant-based eating is growing fast.</p>
                  <p>It seems like every day, there are new plant-based foods in the marketplace.</p>
                  <p>
                    Schools, hospitals, and even fast-food restaurants are encouraging plant-based options. And in 2023,
                    the US Conference of Mayors, which represents 1,400 cities across the US, adopted a resolution
                    calling for the promotion of plant-based diets to support human health, medical care savings, and
                    environmental restoration.
                  </p>
                  <p>
                    Food Revolution Network is feeling the love, too. Our membership is soaring with over a million
                    people now subscribed to our newsletters. And we’re just getting started!
                  </p>
                  <p>Yet very few plant-based coaches exist (so far).</p>
                  <p>This means there’s a huge opportunity.</p>
                  <p>
                    {
                      {
                        default: (
                          <>
                            If you join me and our guest experts for this workshop, we promise to share how you can turn
                            your passion into impact so you can help people accomplish their health goals — improving
                            human lives and our planet for generations to come.
                          </>
                        ),
                        ad: (
                          <>
                            If you join me and our guest experts for this workshop, we promise to share how to turn
                            passion into impact and help people accomplish their health goals — improving human lives
                            and the planet for generations to come.
                          </>
                        )
                      }[variant]
                    }
                  </p>
                  <p>
                    {
                      {
                        default: (
                          <>
                            We’re bringing in luminaries from the coaching, business-building, and lifestyle worlds (all
                            socially responsible and evidence-based) to show you what’s possible in plant-based coaching
                            and how you can facilitate positive changes for your clients. For life.
                          </>
                        ),
                        ad: (
                          <>
                            We’re bringing in luminaries from the coaching, business-building, and lifestyle worlds (all
                            socially responsible and evidence-based) to show what’s possible in plant-based coaching and
                            how to facilitate positive changes for clients. For life.
                          </>
                        )
                      }[variant]
                    }
                  </p>
                  <p>See you there!</p>
                  <img
                    className="w-auto"
                    src="https://cdn.foodrevolution.org/global/team/ocean-signature-firstname.png"
                    alt="ocean signature"
                    width={1000}
                    height={249}
                    style={{maxHeight: '60px'}}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="pbccw-facilitators" color="white" angle="both">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center">Your Workshop Facilitators</h2>
                <Row className="align-items-stretch justify-content-center text-center">
                  {workshopSpeakerList.map((item, i) => {
                    return (
                      <Fragment key={item + i}>
                        {speakers
                          .filter((speaker) => speaker.id === item.id)
                          .map((speaker) => {
                            const [isOpen, setIsOpen] = useState(false);
                            const onClick = useCallback(() => setIsOpen(true), []);
                            const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
                            return (
                              <Col key={'speaker' + i} xs="6" lg="3" className="mb-4 d-flex flex-column">
                                <img className="box-shadow-image rounded mb-3" src={speaker.image} />
                                <h6 className="text-base mt-0 mb-2">{speaker.name}</h6>
                                <p className="balance-text text-16 mb-3">{speaker.tagline}</p>
                                {speaker.bio && (
                                  <div className="mt-auto">
                                    <Button color="green" onClick={onClick} className="text-small text-white" block>
                                      Learn More
                                    </Button>
                                    <Modal isOpen={isOpen} toggle={toggle} size="lg">
                                      <Container className="px-sm-4 pt-0 pb-5" fluid>
                                        <Row className="d-flex justify-content-center">
                                          <Col xs="10" lg="4" className="mb-4 mb-lg-0">
                                            <LazyLoad height={300} offset={100}>
                                              <img className="box-shadow-image rounded" src={speaker.image} />
                                            </LazyLoad>
                                          </Col>
                                          <Col xs="12" lg="8">
                                            <div className="speaker-details">
                                              <h3 className="speaker-name section-heading mb-2">{speaker.name}</h3>
                                              <ReactMarkdown className="speaker-description" source={speaker.bio} />
                                            </div>
                                          </Col>
                                        </Row>
                                      </Container>
                                    </Modal>
                                  </div>
                                )}
                              </Col>
                            );
                          })}
                      </Fragment>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="" color="light-gray" className="text-center">
          <Container>
            <Row>
              <Col>
                <img
                  className="mb-4"
                  src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg"
                  alt="Food Revolution Network logo"
                  width={300}
                  height={126}
                  style={{maxHeight: '200px'}}
                />
                <p className="balance-text">
                  Food Revolution Network’s mission is to educate, inspire, and empower on behalf of healthy, ethical,
                  and sustainable food for all.
                </p>
                <p className="balance-text">
                  Guided by John and Ocean Robbins, with more than a million members and with the collaboration of many
                  of the top food revolutionary leaders of our times, FRN aims to change the way the world eats.
                </p>
                <p className="balance-text">
                  As a certified B Corp, we commit to these values in every aspect of our work.
                </p>
                <img
                  src="https://cdn.foodrevolution.org/global/b-corp-logo-black.svg"
                  alt="B Corp Logo"
                  width={103}
                  height={150}
                  style={{maxHeight: '200px'}}
                />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="pbccw-info" color="white">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center mb-5">Join us LIVE August 23–25, 2024!</h3>
                <ImgCard
                  img="https://cdn.foodrevolution.org/pbcc/images/1475119906.jpg"
                  className="imgBulletCard-300 mb-4"
                >
                  <h5 className="section-heading mb-3">Day 1: Nutrition</h5>
                  <p className="mb-0">
                    {
                      {
                        default: (
                          <>
                            You’ll find out about the power of plants to heal lives and prevent chronic illness. We’ll
                            also share a research-based analysis of the key benefits and pitfalls of a plant-based diet
                            — and how to maximize the benefits and overcome the dangers. Plus, we’ll explore FRN’s
                            approach to embracing a whole food plant-based lifestyle. This is for you if you believe
                            that food matters and you would like to help people optimize nutrition in their lives.
                          </>
                        ),
                        ad: (
                          <>
                            Learn about the power of plants to heal lives and heal the planet. We’ll also share a
                            research-based analysis of the key benefits and pitfalls of a plant-based diet — and how to
                            maximize the benefits and overcome the dangers. Plus, we’ll explore FRN’s approach to
                            embracing a whole food plant-based lifestyle. This is for you if you believe that food
                            matters and you would like to help people optimize nutrition in their lives.
                          </>
                        )
                      }[variant]
                    }
                  </p>
                </ImgCard>
                <ImgCard
                  img="https://cdn.foodrevolution.org/pbcc/images/1153442188.jpg"
                  className="imgBulletCard-300 mb-4"
                >
                  <h5 className="section-heading mb-3">Day 2: Coaching</h5>
                  <p className="mb-0">
                    {
                      {
                        default: (
                          <>
                            We’ll look at breakthrough research on how to create lasting behavior change. Discover how
                            structure paves the way for client success, learn FRN's essential coaching skills and unique
                            techniques, and witness the magic of coaching in action with an engaging live demonstration.
                            This is for you if you’re passionate about helping others make the transition from{' '}
                            <i>knowing what to do</i> to <i>doing what they know</i> — so they can achieve lasting
                            results.
                          </>
                        ),
                        ad: (
                          <>
                            We’ll examine the breakthrough research on how to create lasting behavior change. Discover
                            how structure paves the way for client success, learn FRN's essential coaching skills and
                            unique techniques, and witness the magic of coaching in action with an engaging live
                            demonstration. This is for you if you’re passionate about helping others make the transition
                            from <i>knowing what to do</i> to <i>doing what they know</i> — so they can achieve lasting
                            results.
                          </>
                        )
                      }[variant]
                    }
                  </p>
                </ImgCard>
                <ImgCard
                  img="https://cdn.foodrevolution.org/pbcc/images/897266260.jpg"
                  className="imgBulletCard-300 mb-5"
                >
                  <h5 className="section-heading mb-3">Day 3: Impact</h5>
                  <p className="mb-0">
                    {
                      {
                        default: (
                          <>
                            We’ll look at the expanding field of plant-based coaching and the many ways to make a hobby,
                            a side gig, or a thriving career out of helping people heal their lives. We’ll look at the
                            employment opportunities available in the field, and at how existing healthcare
                            professionals can bring lifestyle medicine into their practice. You’ll learn how you could
                            build a business as a plant-based coach. And we’ll look at proven strategies for making an
                            impact in your family, your community, and on our planet.
                          </>
                        ),
                        ad: (
                          <>
                            We’ll look at the expanding field of plant-based coaching and the many ways to make a hobby,
                            a side gig, or a thriving career helping people heal with plant-based food. We’ll look at
                            the employment opportunities available in the field, and at how existing healthcare
                            professionals can bring lifestyle medicine into their practice. Learn how to build a
                            business as a plant-based coach and discover proven strategies for making an impact in your
                            family, your community, and on the planet.
                          </>
                        )
                      }[variant]
                    }
                  </p>
                </ImgCard>
                <p>
                  We’ll also introduce Food Revolution Network’s Plant-Based Coaching Certification, where you can gain
                  the in-demand skills to:
                </p>
                <ListIcon>
                  <>
                    {
                      {
                        default: (
                          <>
                            Launch a successful business as a Plant-Based Coach, or add plant-based coaching to your
                            existing practice.
                          </>
                        ),
                        ad: (
                          <>
                            Launch a successful business as a Plant-Based Coach, or add plant-based coaching to an
                            existing practice.
                          </>
                        )
                      }[variant]
                    }
                  </>
                  <>Get hired as a part-time or full-time employee by forward-thinking companies and organizations.</>
                  <>Be part of the plant-based coaching revolution.</>
                </ListIcon>
                <p>
                  <i>*With a special offer that’s only available for workshop participants!</i>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="pbccw-cta-final"
          color="purple"
          imgPosition="left"
          imgUrl="https://cdn.foodrevolution.org/pbcc/images/1498082066-square.jpg"
          className="landing-final-cta section-angle-last text-center text-white"
          angle="none"
        >
          <h2 className="section-heading">It’s Time for a Plant-Based Food Revolution.</h2>
          <h3 className="mt-0 mb-5">
            <i>Let’s do this, together.</i>
          </h3>
          <OptinModalTrigger>{ctaButtonCopy}</OptinModalTrigger>
        </SectionImage>
      </div>

      {!noExitIntent && <ModalExitLanding />}

      <div className="footer-content mt-auto">
        <Footer />
      </div>

      <OptinModal
        contactFormHeader={
          <>
            <p className="text-center">
              <b>Enter your name and email below to sign up.</b>
            </p>
          </>
        }
        btnText="Save Your Spot"
      />
    </div>
  );
}
